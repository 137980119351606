<template>
  <v-row class="ma-4 pt-5">
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="sum" :label="(type==='ipoteka'?'Стоимость недвижимости':'Сумма кредита')+', ₽'" hide-details type="number" @blur="sliderStepSum=10000" @focus="sliderStepSum=1"/>
          <v-slider ref="sumSlider" v-model="sum" :step="sliderStepSum" class="w-slider" hide-details max="100000000" min="0"/>
        </v-col>
        <v-col v-if="type==='ipoteka'" cols="12">
          <v-text-field v-model="initial" hide-details label="Первоначальный взнос, ₽" type="number" @blur="sliderStepInitial=10000" @focus="sliderStepInitial=1"/>
          <v-slider v-model="initial" :max="sum" :step="sliderStepInitial" class="w-slider" hide-details min="0"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="rate" hide-details label="Процентная ставка, %" type="number"/>
            <v-slider v-model="rate" class="w-slider" hide-details max="100" min="0.1" step="0.1"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="term" hide-details label="Срок кредита, лет" type="number"/>
            <v-slider v-model="term" class="w-slider" hide-details max="50" min="1" step="1"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="dependents" hide-details label="Количество иждивенцев, чел" max="30" min="0" type="number"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="payments" hide-details label="Ежемесячный платеж по кредитам, ₽" type="number"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col class="mb-4" cols="12">
          <list-item title="Ежемесячный платеж">
            <h3>{{ formatMoney(monthPayment) }}</h3>
          </list-item>
        </v-col>
        <v-col class="mb-4" cols="12">
          <list-item title="Сумма выплат банку за весь период с учетом процентов">
            <h3>{{ formatMoney(totalPayment) }}</h3>
          </list-item>
        </v-col>
        <v-col class="mb-4" cols="12">
          <list-item title="Величина переплаты">
            <h3>{{ formatMoney(overPayment) }}</h3>
          </list-item>
        </v-col>
        <v-col class="mb-4" cols="12">
          <list-item title="Необходимый доход">
            <h3>{{ formatMoney(needIncome) }}</h3>
          </list-item>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ListItem      from "@/tags/ListItem";
import {formatMoney} from "@/helpers/stringHelpers";

export default {
  name:       "Calculator",
  components: {ListItem},
  props:      ['type'],
  data() {
    return {
      sum:               5000000,
      initial:           1000000,
      rate:              10,
      term:              10,
      dependents:        0,
      payments:          0,
      sliderStepSum:     10000,
      sliderStepInitial: 10000
    }
  },
  computed: {
    monthRate() {
      return this.rate * 0.01 * (1 / 12);
    },
    monthTerm() {
      return this.term * 12;
    },
    totalSum() {
      return this.sum - (this.type === 'ipoteka' ? this.initial : 0);
    },
    monthPayment() {
      return this.totalSum * (this.monthRate / (1 - (1 + this.monthRate) ** (-this.monthTerm)));
    },
    totalPayment() {
      return this.monthPayment * this.monthTerm;
    },
    overPayment() {
      return this.totalPayment - this.totalSum;
    },
    needIncome() {
      const minIncome = 13500;
      return minIncome + (this.dependents * minIncome) + parseInt(this.payments) + this.monthPayment;
    }
  },
  methods: {
    formatMoney
  }
}
</script>

<style>
.w-slider {
  max-width: calc(100% + 16px);
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -16px;
}
</style>