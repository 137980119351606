import { render, staticRenderFns } from "./VideoView.vue?vue&type=template&id=a818ccfa&scoped=true"
import script from "./VideoView.vue?vue&type=script&lang=js"
export * from "./VideoView.vue?vue&type=script&lang=js"
import style0 from "./VideoView.vue?vue&type=style&index=0&id=a818ccfa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a818ccfa",
  null
  
)

export default component.exports