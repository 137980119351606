<template>
  <div>
    <quote-bar/>
    <v-app-bar app color="#FFFDFD">
      <v-app-bar-nav-icon class="ml-md-0" color="secondary-lite" @click="drawer = !drawer"/>
      <v-scale-transition>
        <v-app-bar-title v-if="!drawer">
          <v-img :src="logoSrc" contain max-height="48px" position="left"/>
        </v-app-bar-title>
      </v-scale-transition>

      <v-spacer/>

      <template v-if="!isGuest">
        <notify/>
        <stat/>
        <income-stat/>
      </template>

      <template v-else>
        <v-btn :to="{ name: 'Login' }" plain>{{ $t('login.signIn') }}</v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-if="!isGuest" v-model="drawer" :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm" app class="nav-drawer" color="#FFFCFC">
      <account :drawer="drawer"/>
      <nav-bar/>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters}          from 'vuex';
import NavBar                from '@/layouts/NavBar';
import {apiUri, formatMoney} from '@/helpers/stringHelpers';
import Notify                from '@/layouts/components/Notify.vue';
import Stat                  from "@/layouts/components/Stat";
import QuoteBar              from "@/layouts/components/QuoteBar";
import Account               from "@/layouts/components/Account.vue";
import IncomeStat            from "@/layouts/components/IncomeStat.vue";

export default {
  name:       'AppBar',
  components: {IncomeStat, Account, QuoteBar, NavBar, Notify, Stat},
  computed:   {
    ...mapGetters(['user', 'isGuest', 'can']),
    logoSrc() {
      let logo = require('@/assets/logo.svg');
      if (this.can('office.logo') && this.user.logo) {
        logo = this.apiUri(this.user.logo);
      }
      return logo;
    }
  },
  data() {
    return {
      appName:    process.env.VUE_APP_NAME,
      drawer:     this.$vuetify.breakpoint.mdAndUp,
      statIncome: 0,
      statPlace:  0
    };
  },
  methods: {
    apiUri,
    formatMoney
  }
};
</script>

<style scoped>
.min-width {
  width: min-content;
}

.nav-drawer {
  box-shadow: 0 0 10px 0 #0000001A;
}
</style>
<style>
.v-navigation-drawer__border {
  display: none;
}
</style>