<template>
  <modal v-model="modal" :closable="false" :loading="loading" max-width="1200px" title="Вступительное видео">
    <template v-slot:activator>
      <slot v-if="showVideoStartBtn"/>
    </template>
    <v-responsive :aspect-ratio="16/9">
      <iframe v-if="modal && embed" :src="embed" allowfullscreen="allowfullscreen" class="player"/>
      <v-card v-else class="text--disabled pa-8 text-center align-center d-flex fill-height justify-center">
        Не удалось загрузить видео
      </v-card>
    </v-responsive>
    <template #actions>
      <v-btn :disabled="!allowAccept" color="primary" @click="acceptVideoStart()">Ознакомлен</v-btn>
    </template>
  </modal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Modal                                  from "../../tags/Modal";

export default {
  name:       "VideoStartView",
  components: {Modal},
  mounted() {
    if (this.notifyData?.setting_video_start?.count > 0) {
      this.modal = true;
    }

  },
  data: () => ({
    modal:       false,
    loading:     false,
    openTime:    0,
    timer:       undefined,
    currentTime: new Date().getTime()
  }),
  watch: {
    modal(value) {
      if (value) {
        this.openTime = new Date().getTime();
        this.updateCurrentTime();
      } else {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = undefined;
        }
      }
      this.setKeepActive(value);
    }
  },
  computed: {
    ...mapGetters(['setting', 'notifyData']),
    embed() {
      if (!this.videoStartUrl) {
        return false;
      }
      let urlParams = new URLSearchParams(this.videoStartUrl.split('?')[1]);
      let v = urlParams.get('v');
      return '//www.youtube.com/embed/' + v + '?autoplay=1&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=1&showinfo=0';
    },
    allowAccept() {
      if (!this.notifyData?.setting_video_start?.count) {
        return true;
      }
      return 10000 < this.currentTime - this.openTime;
    },
    videoStartUrl() {
      return this.setting?.content?.video_start?.url || '';
    },
    showVideoStartBtn() {
      return !!this.videoStartUrl;
    },
    openVideoStart() {
      return this.videoStartUrl && this.notifyData?.setting_video_start?.count > 0;
    }
  },
  methods: {
    ...mapMutations(['setKeepActive']),
    ...mapActions(['updateNotify']),
    acceptVideoStart() {
      this.updateNotify('setting_video_start');
      this.modal = false;
    },
    updateCurrentTime() {
      this.timer = setTimeout(() => {
        this.currentTime = new Date().getTime();
        this.updateCurrentTime();
      }, 1000)
    }
  }
}
</script>

<style scoped>
.player {
  width: 100%;
  height: 100%;
  border: none;
}
</style>