import Vue                      from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Index                    from '@/views/index/Index.vue';
import Login                    from '@/views/auth/Login.vue';
import PasswordResetRequest     from '@/views/auth/PasswordResetRequest.vue';
import PasswordReset            from '@/views/auth/PasswordReset.vue';
import Error404                 from '@/views/errors/Error404.vue';
import Error403                 from '@/views/errors/Error403.vue';
import UserIndex                from '@/views/users/Index.vue';
import RoleIndex                from '@/views/roles/Index.vue';
import OfficeIndex              from '@/views/office/Index.vue';
import BankIndex                from '@/views/bank/Index.vue';
import BankList                 from '@/views/bankList/Index.vue';
import ClientSourcesIndex       from '@/views/clientSources/Index.vue';
import LeadTypesIndex           from '@/views/leadTypes/Index.vue';
import ServiceTypesIndex        from '@/views/serviceTypes/Index.vue';
import RejectionReasonsIndex    from '@/views/rejectionReasons/Index.vue';
import ClientStatusesIndex      from '@/views/clientStatuses/Index.vue';
import DealStatusesIndex        from '@/views/dealStatuses/Index.vue';
import ClientCategoryIndex      from '@/views/clientCategory/Index.vue';
import LinkCategoryIndex        from '@/views/linkCategory/Index.vue';
import VideoCategoryIndex       from '@/views/videoCategory/Index.vue';
import TaskCategoryIndex        from '@/views/taskCategory/Index.vue';
import VideoIndex               from '@/views/videos/Index.vue';
import RefundReasonsIndex       from '@/views/refundReasons/Index.vue';
import ClientsIndex             from '@/views/clients/Index.vue';
import DealsIndex               from '@/views/deals/Index.vue';
import LinksIndex               from '@/views/links/Index.vue';
import ClientImport             from '@/views/clients/Import.vue';
import ClientExport             from '@/views/clients/Export.vue';
import CalculatorIndex          from '@/views/calculator/Index.vue';
import NewsIndex                from '@/views/news/Index.vue';
import FaqIndex                 from '@/views/faq/Index.vue';
import ArticleIndex             from '@/views/articles/Index.vue';
import FinServiceIndex          from '@/views/finService/Index.vue';
import HrTestIndex              from '@/views/hrTest/Index.vue';
import HrTestManageIndex        from '@/views/hrTestManage/Index.vue';
import TaskIndex                from '@/views/tasks/Index.vue';
import StatIndex                from '@/views/stat/Index.vue';
import SupportServiceIndex      from '@/views/supportService/Index.vue';
import SupportContactIndex      from '@/views/supportContact/Index.vue';
import EventIndex               from '@/views/event/Index.vue';
import DocumentFolderIndex      from '@/views/documents/Index.vue';
import SettingIndex             from '@/views/setting/Index.vue';
import BookIndex                from '@/views/book/Index.vue';
import ClientCalendarIndex      from '@/views/clientCalenar/Index.vue';
import ClientFieldIndex         from '@/views/clientField/Index.vue';
import MsScriptIndex            from '@/views/msScript/Index.vue';
import DealPaymentTypeIndex     from '@/views/dealPaymentTypes/Index.vue'
import ContractFileIndex        from '@/views/contractFiles/Index.vue';
import TestIndex                from '@/views/test/Index.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path:      '/',
        name:      'Home',
        component: Index,
        meta:      {permissions: '@'}
    },
    {
        path:      '/login',
        name:      'Login',
        component: Login,
        meta:      {permissions: '?'}
    },
    {
        path:      '/password-reset-request',
        name:      'PasswordResetRequest',
        component: PasswordResetRequest,
        meta:      {permissions: '?'}
    },
    {
        path:      '/password-reset',
        name:      'PasswordReset',
        component: PasswordReset,
        meta:      {permissions: '?'}
    },
    {
        path:      '/users',
        name:      'Users',
        component: UserIndex,
        meta:      {permissions: 'user.manage'}
    },
    {
        path:      '/roles',
        name:      'Roles',
        component: RoleIndex,
        meta:      {permissions: 'role.manage'}
    },
    {
        path:      '/offices',
        name:      'Offices',
        component: OfficeIndex,
        meta:      {permissions: 'office.manage'}
    },
    {
        path:      '/banks',
        name:      'Banks',
        component: BankIndex,
        meta:      {permissions: 'bank.manage'}
    },
    {
        path:      '/bank-list',
        name:      'BankList',
        component: BankList,
        meta: {permissions: (vm: any) => vm.office.is_head && vm.can('bank.view')}
    },
    {
        path:      '/client-sources',
        name:      'ClientSources',
        component: ClientSourcesIndex,
        meta:      {permissions: 'client-source.manage'}
    },
    {
        path:      '/lead-types',
        name:      'LeadTypes',
        component: LeadTypesIndex,
        meta:      {permissions: 'lead-type.manage'}
    },
    {
        path:      '/service-types',
        name:      'ServiceTypes',
        component: ServiceTypesIndex,
        meta:      {permissions: 'service-type.manage'}
    },
    {
        path:      '/rejection-reasons',
        name:      'RejectionReasons',
        component: RejectionReasonsIndex,
        meta:      {permissions: 'rejection-reason.manage'}
    },
    {
        path:      '/client-statuses',
        name:      'ClientStatuses',
        component: ClientStatusesIndex,
        meta:      {permissions: 'client-status.manage'}
    },
    {
        path:      '/deal-statuses',
        name:      'DealStatuses',
        component: DealStatusesIndex,
        meta:      {permissions: 'deal-status.manage'}
    },
    {
        path:      '/client-category',
        name:      'ClientCategory',
        component: ClientCategoryIndex,
        meta:      {permissions: 'client-category.manage'}
    },
    {
        path:      '/link-category',
        name:      'LinkCategory',
        component: LinkCategoryIndex,
        meta:      {permissions: 'link-category.manage'}
    },
    {
        path:      '/task-category',
        name:      'TaskCategory',
        component: TaskCategoryIndex,
        meta:      {permissions: 'task-category.manage'}
    },
    {
        path:      '/refund-reasons',
        name:      'RefundReasons',
        component: RefundReasonsIndex,
        meta:      {permissions: 'refund-reason.manage'}
    },
    {
        path:      '/clients',
        name:      'Clients',
        component: ClientsIndex,
        meta:      {permissions: 'client.manage'}
    },
    {
        path:      '/deals',
        name:      'Deals',
        component: DealsIndex,
        meta:      {permissions: 'deal.manage'}
    },
    {
        path:      '/links',
        name:      'Links',
        component: LinksIndex,
        meta:      {permissions: 'link.view'}
    },
    {
        path:      '/client-import',
        name:      'ClientImport',
        component: ClientImport,
        meta:      {permissions: 'client.import'}
    },
    {
        path:      '/client-export',
        name:      'ClientExport',
        component: ClientExport,
        meta:      {permissions: 'client.export'}
    },
    {
        path:      '/calculator',
        name:      'Calculator',
        component: CalculatorIndex,
        meta:      {permissions: 'calculator.manage'}
    },
    {
        path:      '/video-category',
        name:      'VideoCategory',
        component: VideoCategoryIndex,
        meta:      {permissions: 'video-category.manage'}
    },
    {
        path:      '/videos',
        name:      'Videos',
        component: VideoIndex,
        meta:      {permissions: 'video.view'}
    },
    {
        path:      '/news',
        name:      'News',
        component: NewsIndex,
        meta:      {permissions: 'news.view'}
    },
    {
        path:      '/faq',
        name:      'Faq',
        component: FaqIndex,
        meta:      {permissions: 'faq.view'}
    },
    {
        path:      '/articles',
        name:      'Article',
        component: ArticleIndex,
        meta:      {permissions: 'article.view'}
    },
    {
        path:      '/fin-service',
        name:      'FinService',
        component: FinServiceIndex,
        meta:      {permissions: 'fin-service.view'}
    },
    {
        path:      '/hr-test',
        name:      'HrTest',
        component: HrTestIndex,
        meta:      {permissions: (vm: any) => (vm.can('hr-result.manage') || vm.can('hr-result.pass')) && vm.office.hr_test}
    },
    {
        path:      '/hr-test-manage',
        name:      'HrTestManage',
        component: HrTestManageIndex,
        meta:      {permissions: 'hr-test.manage'}
    },
    {
        path:      '/event',
        name:      'Event',
        component: EventIndex,
        meta:      {permissions: 'event.view'}
    },
    {
        path:      '/tasks',
        name:      'Tasks',
        component: TaskIndex,
        meta:      {permissions: 'task.manage'}
    },
    {
        path:      '/stat',
        name:      'Stat',
        component: StatIndex,
        // meta:      {permissions: () => false}
        meta: {permissions: 'stat.manage'}
    },
    {
        path:      '/support-service',
        name:      'SupportService',
        component: SupportServiceIndex,
        meta:      {permissions: 'support-service.view'}
    },
    {
        path:      '/support-contact',
        name:      'SupportContact',
        component: SupportContactIndex,
        meta:      {permissions: 'support-contact.view'}
    },
    {
        path:      '/documents-client',
        name:      'DocumentsClient',
        component: DocumentFolderIndex,
        props: {category: 0},
        meta:  {permissions: ['document.manage', 'document.view', 'document.view.all']}
    },
    {
        path:      '/documents-education',
        name:      'DocumentsEducation',
        component: DocumentFolderIndex,
        props: {category: 1},
        meta:  {permissions: ['document.manage', 'document.view', 'document.view.all']}
    },
    {
        path:      '/setting',
        name:      'Setting',
        component: SettingIndex,
        meta:      {permissions: 'setting.manage'}
    },
    {
        path:      '/book',
        name:      'Book',
        component: BookIndex,
        meta:      {permissions: ['book.manage', 'book.view']}
    },
    {
        path:      '/client-calendar',
        name:      'ClientCalendar',
        component: ClientCalendarIndex,
        meta:      {permissions: 'client.calendar'}
    },
    {
        path:      '/client-field',
        name:      'ClientField',
        component: ClientFieldIndex,
        meta:      {permissions: 'client-field.manage'}
    },
    {
        path:      '/ms-script',
        name:      'MsScript',
        component: MsScriptIndex,
        meta:      {permissions: 'ms-script.manage'}
    },
    {
        path:      '/deal-payment-type',
        name:      'DealPaymentType',
        component: DealPaymentTypeIndex,
        meta:      {permissions: 'deal-payment-type.manage'}
    },
    {
        path:      '/contract-file',
        name:      'ContractFile',
        component: ContractFileIndex,
        meta:      {permissions: 'contract-file.manage'}
    },
    {
        path:      '/test',
        name:      'Test',
        component: TestIndex,
        meta:      {permissions: (vm: any) => true}
    },
    {
        path:      '/denied',
        name:      '403',
        component: Error403
    },
    {
        path:      '*',
        name:      '404',
        component: Error404
    }
];

const router = new VueRouter({
                                 mode: 'history',
                                 base: process.env.BASE_URL,
                                 routes
                             });

export default router;