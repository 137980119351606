<template>
  <v-file-input
      v-model="file"
      :accept="accept"
      :background-color="backgroundColor"
      :hint="hint"
      :label="label"
      :multiple="multiple"
      :persistent-hint="persistentHint"
      :prepend-inner-icon="icon"
      :rules="rules"
      prepend-icon=""
      show-size
      ref="inputFile"
  >
    <template #append-outer>
      <slot name="append-outer"/>
    </template>
  </v-file-input>
</template>

<script>
export default {
  name:  'FileInput',
  props: {
    value: {
      default() {
        return [];
      }
    },
    rules: {
      type: Array
    },
    icon: {
      type:    String,
      default: 'mdi-paperclip'
    },
    label: {
      type: String
    },
    accept: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    multiple: {
      type:    Boolean,
      default: false
    },
    hint: {
      type: String
    },
    persistentHint: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {
      file: this.value || []
    };
  },
  watch: {
    file(value) {
      this.$emit('input', value);
    }
  },
  methods: {
    validate() {
      this.$refs.inputFile.validate();
    }
  }
};
</script>

<style scoped></style>