<template>
  <v-card>
    <v-title title="Книги"/>
    <v-card-title>
      <span class="mr-5">Книги</span>
      <span v-if="can('book.manage')">
      <book-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </book-form>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      </span>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-list
        :models="models"
    >

      <template v-slot:item="{ item }">
        <v-col cols="12">
          <v-img v-if="item.image" :class="$vuetify.breakpoint.xs?'mb-5 mx-auto':'mb-5 mr-5 float-left'" :src="apiUri(item.image)" max-width="200px"/>
          <div>
            <h2>{{ item.name }}</h2>
            <v-subheader class="pl-0">{{ item.author }}</v-subheader>
          </div>
          <div v-if="item.description" class="text-pre-wrap">{{ item.description }}</div>
          <v-btn :href="item.url" class="mt-2" color="primary" prepend-icon="mdi-pencil" target="_blank">
            Скачать
            <v-icon right>
              mdi-open-in-new
            </v-icon>
          </v-btn>
          <div v-if="can('book.manage')" class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <book-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </book-form>
          </span>
            <span v-if="!item.deleted_at" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
            <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
          </div>
        </v-col>
      </template>
    </data-list>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete        from "@/views/news/Delete";
import {mapGetters}  from "vuex";
import ToggleDeleted from "@/views/news/ToggleDeleted";
import Restore       from "@/views/news/Restore";
import VTitle        from "@/tags/VTitle";
import BtnIcon       from "@/tags/BtnIcon";
import DataList      from "../../tags/DataList";
import {apiUri}      from "@/helpers/stringHelpers";
import BookForm      from "./BookForm";


export default {
  name:       "Index",
  components: {BookForm, DataList, BtnIcon, VTitle, Restore, ToggleDeleted, Delete},
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'book',
      fields: ['id', 'name', 'author', 'image', 'url', 'description', 'created_at', 'deleted_at'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}, {author: {like: ''}}, {description: {like: ''}}]}
    }).load(),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['can'])
  },
  watch: {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.models.config.filter.or[1].author.like = value || '';
      this.models.config.filter.or[2].description.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    apiUri
  }
}
</script>

<style scoped>

</style>