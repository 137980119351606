<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение офиса':'Создание офиса'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.org_name" :label="model.labels.org_name" :rules="model.rule('org_name')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.email" :label="model.labels.email" :rules="model.rule('email')"/>
          </v-col>
          <v-col cols="12" md="6">
            <phone-input v-model="model.attr.phone" :label="model.labels.phone" :rules="model.rule('phone')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.legal_address" :label="model.labels.legal_address" :rules="model.rule('legal_address')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.real_address" :label="model.labels.real_address" :rules="model.rule('real_address')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.post_address" :label="model.labels.post_address" :rules="model.rule('post_address')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.ogrn" :label="model.labels.ogrn" :rules="model.rule('ogrn')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.inn" :label="model.labels.inn" :rules="model.rule('inn')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.kpp" :label="model.labels.kpp" :rules="model.rule('kpp')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.payment_account" :label="model.labels.payment_account" :rules="model.rule('payment_account')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.corporate_account" :label="model.labels.corporate_account" :rules="model.rule('corporate_account')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.bank_name" :label="model.labels.bank_name" :rules="model.rule('bank_name')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.bik" :label="model.labels.bik" :rules="model.rule('bik')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.general_manager" :label="model.labels.general_manager" :rules="model.rule('general_manager')"/>
          </v-col>
          <v-col cols="12" md="6">
            <file-input v-model="model.attr.logo_file" :label="model.labels.logo_file" :rules="model.rule('logo_file')" accept=".png,.jpg,.svg" icon="mdi-file-image"/>
          </v-col>
        </v-row>
        <v-row>
          <!--          <v-col cols="12" md="6">
                      <v-switch v-model="model.attr.is_head" :false-value="0" :label="model.labels.is_head" :true-value="1"/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-switch v-model="model.attr.show_denied" :false-value="0" :label="model.labels.show_denied" :true-value="1"/>
                    </v-col>-->
          <v-col col="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal      from "@/tags/Modal";
import {Office}   from "@/models/Office";
import FileInput  from "@/tags/FileInput";
import PhoneInput from "@/tags/PhoneInput";


export default {
  name:       "OfficeSelfForm",
  components: {FileInput, Modal, PhoneInput},
  props:      ['id'],
  data() {
    return {
      modal: false,
      model: Office.get(this.id, {
        on: {
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      })
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>