export default {
    label: {
        comment: 'Комментарий'
    },
    btn: {
        close:       'Закрыть',
        save:        'Сохранить',
        confirm:     'Подтвердить',
        search:      'Поиск',
        edit:        'Изменить',
        delete:      'Удалить',
        restore:     'Восстановить',
        manage:      'Управление',
        create:      'Создать',
        block:       'Заблокировать',
        unblock:     'Разблокировать',
        hideDeleted: 'Скрыть удаленное',
        showDeleted: 'Показать удаленное'
    },
    login: {
        login:    'Логин',
        password: 'Пароль',
        signIn:   'Вход',
        signOut:  'Выход',
        auth:     'Авторизация'
    },
    home: {
        title: 'Главная'
    },
    role: {
        title:         'Роли',
        create:        'Создать Роль',
        manage:        'Управление Ролью',
        edit:          'Изменение Роли',
        confirmDelete: 'Вы действительно хотите удалить роль {name}?',
        name:          'Название',
        code:          'Код',
        permissions:   'Права',
        users:         'Пользователи',
        actions:       'Действия'
    },
    user: {
        title:          'Менеджеры',
        create:         'Создать менеджера',
        edit:           'Изменение менеджера',
        confirmDelete:  'Вы действительно хотите удалить менеджера {name}?',
        confirmRestore: 'Вы действительно хотите восстановить менеджера {name}?',
        login:          'ФИО',
        email:          'E-mail',
        password:       'Пароль',
        role:           'Роль',
        actions:        'Действия'
    },
    error: {
        notFound: 'Эта страница не найдена',
        denied:   'Эта страница недоступна'
    },
    service: {
        title:         'Выбрать услугу',
        sendDocuments: 'Отправить документы',
        comment:       'Желаемый банк при ипотеке и вид страхования',
        1:             'Заказать юридическую проверку объекта недвижимости и продавца',
        2:             'Заказать оценку объекта',
        3:             'Составить договор купли-продажи',
        4:             'Оформить страховой полис',
        5:             'Составить соглашение о покупке-продаже недвижимости',
        6:             'Одобрить ипотеку'
    },
    file: {
        requirements: {
            title: 'Пожелания при отправке документов',
            1:     'Один файл один документ;',
            2:     'Файл pdf, jpg и docx;',
            3:     'Наименование файла соответствует документу;',
            4:     'Файл не более 3 Мб., если файл более 3 Мб – pro CRM SF не принимает'
        },
        category: {
            1: 'Общие документы',
            2: 'При рефинансировании дополнительно',
            3: 'Для нерезидентов дополнительно',
            4: 'Для собственника бизнеса дополнительно',
            5: 'Для банка'
        },
        1:  'Свидетельство о собственности и (или) выписка из ЕГРН с указанием документов-основания от всех собственников',
        2:  'Правоустанавливающие документы на недвижимость',
        3:  'Расписка от продавца о получении денежных средств или платежное поручение',
        4:  'Передаточный акт на недвижимость',
        5:  'Паспорта всех собственников, обязательно все листы',
        6:  'Справки из ПНД и НД',
        7:  'Нотариально удостоверенное согласие супруга (-ги) продавцов объекта или нотариальное заявление об отсутствии брака в момент приобретения недвижимости и на дату подписания ДКП',
        8:  'Документы из БТИ: поэтажный план и экспликация или технический паспорт',
        9:  'Выписка из домовой книги, в том числе архивная (расширенная)',
        10: 'Справка об отсутствии задолженности по ЖКХ, электроэнергии, по взносам за капитальный ремонт и из ПФР об использовании (наличии) маткапитала',
        11: 'Расширенная выписка из НБКИ и (или) ОКБ',
        12: 'Иные документы по вашему усмотрению',
        14: 'Свидетельство о собственности и (или) выписка из ЕГРН с указанием документов-основания от всех собственников',
        15: 'Правоустанавливающие документы на недвижимость',
        16: 'Паспорт покупателя, обязательно все листы',
        17: 'Документы из БТИ: поэтажный план и экспликация или технический паспорт',
        18: 'Иные документы по вашему усмотрению',
        19: 'Свидетельство о собственности и (или) выписка из ЕГРН с указанием документов-основания от всех собственников',
        20: 'Правоустанавливающие документы на недвижимость',
        21: 'Паспорта продавцов и покупателей, обязательно все листы',
        22: 'Нотариально удостоверенное согласие супруга (-ги) продавцов объекта или нотариальное заявление об отсутствии брака в момент приобретения недвижимости и на дату подписания ДКП',
        23: 'Выписка из домовой книги, в том числе архивная (расширенная)',
        24: 'Иные документы по вашему усмотрению',
        25: 'Свидетельство о собственности и (или) выписка из ЕГРН с указанием документов-основания от всех собственников',
        26: 'Правоустанавливающие документы на недвижимость',
        27: 'Паспорт покупателя, обязательно все листы',
        28: 'Оценочный альбом',
        29: 'Иные документы по вашему усмотрению',
        30: 'Свидетельство о собственности и (или) выписка из ЕГРН с указанием документов-основания от всех собственников',
        31: 'Правоустанавливающие документы на недвижимость',
        32: 'Паспорта продавцов и покупателей, обязательно все листы',
        33: 'Иные документы по вашему усмотрению',
        34: 'Паспорт',
        35: 'Свидетельство о браке',
        36: 'СНИЛС',
        37: 'ИНН',
        38: 'Диплом ВУЗ',
        39: 'Материнский капитал и справка о наличии суммы на счете',
        40: 'Справка о доходах',
        41: 'Заверенная копия трудовой книжки',
        42: 'Выписка по личному счету за последние 12 месяцев',
        43: 'Заграничный паспорт',
        44: 'Водительское удостоверение',
        45: 'При наличии СТС на авто',
        46: 'При наличии ЕГРН на недвижимость.',
        47: 'ЕГРН и ДКП на недвижимость',
        48: 'Кредитный договор, актуальный график платежей',
        49: 'Справка об остатке долга',
        50: 'Паспорт иностранного гражданина',
        51: 'Нотариальный перевод',
        52: 'ВНЖ или РВП',
        53: 'Миграционная карта',
        54: 'Карточка организации',
        55: 'Выписка по счету организации за последние 12 месяцев',
        56: 'Анкета',
        57: 'Согласие'
    },
    client: {
        printContract: 'Распечатать договор'
    },
    hrTest: {
        titleManage:   'Управление HR SF Test',
        editQuestions: 'Изменить вопросы',
        addQuestion:   'Добавить вопрос',
        addAnswer:     'Добавить ответ'
    }
};