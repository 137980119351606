<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение клиента':'Создание клиента'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <h3>Информация о клиенте</h3>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="model.attr.last_name" :label="model.labels.last_name" :rules="model.rule('last_name')"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="model.attr.first_name" :label="model.labels.first_name" :rules="model.rule('first_name')"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="model.attr.patronymic" :label="model.labels.patronymic" :rules="model.rule('patronymic')"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="model.attr.city" :label="model.labels.city" :rules="model.rule('city')"/>
          </v-col>
          <v-col cols="12" md="4">
            <datetime-utc-input v-model="model.attr.birthday" :label="model.labels.birthday" :rules="model.rule('birthday')" as-string mask only-date/>
          </v-col>
          <v-col cols="12" md="4">
            <v-select v-model="model.attr.gender" :items="genderList" :label="model.labels.gender" :rules="model.rule('gender')" item-text="label" item-value="value"/>
          </v-col>
          <v-col cols="12" md="4">
            <phone-input v-model="model.attr.phone" :label="model.labels.phone" :rules="model.rule('phone')">
              <template v-slot:append-outer>
                <client-duplicate :id="id || 0" :search="model.attr.phone" show-duplicate-btn @load="updatePhoneDuplicate" @copy-profile="fillProfile">
                  <btn-icon v-if="havePhoneDuplicate" color="warning" icon="account-multiple" title="Показать дубликаты"/>
                </client-duplicate>
              </template>
            </phone-input>
          </v-col>
          <v-col cols="12" md="4">
            <phone-input v-model="model.attr.extra_phone" :label="model.labels.extra_phone" :rules="model.rule('extra_phone')">
              <template v-slot:append-outer>
                <client-duplicate :id="id || 0" :search="model.attr.extra_phone" show-duplicate-btn @load="updateExtraPhoneDuplicate" @copy-profile="fillProfile">
                  <btn-icon v-if="haveExtraPhoneDuplicate" color="warning" icon="account-multiple" title="Показать дубликаты"/>
                </client-duplicate>
              </template>
            </phone-input>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="model.attr.email" :label="model.labels.email" :rules="model.rule('email')">
              <template v-slot:append-outer>
                <client-duplicate :id="id || 0" :search="model.attr.email" show-duplicate-btn @load="updateEmailDuplicate" @copy-profile="fillProfile">
                  <btn-icon v-if="haveEmailDuplicate" color="warning" icon="account-multiple" title="Показать дубликаты"/>
                </client-duplicate>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-slider v-model="model.attr.success_chance" :label="model.labels.success_chance" :rules="model.rule('success_chance')" max="10" min="0" step="0.5" thumb-label="always">
              <template v-slot:thumb-label="{ value }">
                {{ value * 10 }}%
              </template>
            </v-slider>
          </v-col>
        </v-row>

        <h3>Дополнительная информация</h3>
        <v-row>
          <v-col cols="12" md="4">
            <mask-input v-model="model.attr.passport" :label="model.labels.passport" :rules="model.rule('passport')" mask="99 99 999999" unmask/>
          </v-col>
          <v-col cols="12" md="4">
            <mask-input v-model="model.attr.snils" :label="model.labels.snils" :rules="model.rule('snils')" mask="999-999-999-99" unmask/>
          </v-col>
          <v-col cols="12" md="4">
            <mask-input v-model="model.attr.inn" :label="model.labels.inn" :rules="model.rule('inn')" mask="9999999999[9][9][9][9][9]" unmask/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.passport_issued" :label="model.labels.passport_issued" :rules="model.rule('passport_issued')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.address_registration" :label="model.labels.address_registration" :rules="model.rule('address_registration')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.address_residence" :label="model.labels.address_residence" :rules="model.rule('address_residence')">
              <template #append-outer>
                <btn-icon color="secondary" icon="content-copy" title="Совпадает с адресом регистрации" @click="copyAddress()"/>
              </template>
            </v-text-field>
          </v-col>
        </v-row>


        <h3>Статус клиента</h3>
        <v-row>
          <v-col cols="12" md="4">
            <datetime-utc-input v-model="model.attr.notice_date" :label="model.labels.notice_date" :max="maxNoticeDate" :rules="model.rule('notice_date')" @input="checkHaveMeet">
              <template v-slot:append-outer>
                <btn-icon v-if="haveMeet" color="error" icon="clock" title="Назначены встречи"/>
                <client-calendar-modal v-if="canViewCalendar" :current-item="model.attr" @update="updateNoticeDate">
                  <btn-icon color="warning" icon="calendar" title="Календарь"/>
                </client-calendar-modal>
              </template>
            </datetime-utc-input>
          </v-col>
          <v-col cols="12" md="4">
            <v-select v-model="model.attr.status_id" :items="availableStatusList" :label="model.labels.status_id" :rules="model.rule('status_id')" item-text="name" item-value="id" @change="checkHaveMeet"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-select v-model="model.attr.category_id" :items="categoryList" :label="model.labels.category_id" :rules="model.rule('category_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col v-if="showRejectionReason" cols="12" md="4">
            <v-autocomplete v-model="model.attr.rejection_reason_id" :items="rejectionReasonList" :label="model.labels.rejection_reason_id" :rules="model.rule('rejection_reason_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col v-if="showRefundReason" cols="12" md="4">
            <v-autocomplete v-model="model.attr.refund_reason_id" :items="refundReasonList" :label="model.labels.refund_reason_id" :rules="model.rule('refund_reason_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col v-if="showMeetingSuccess" cols="12" md="4">
            <v-select v-model="model.attr.meeting_success" :items="meetSuccessList" :label="model.labels.meeting_success" :rules="model.rule('meeting_success')" item-text="label" item-value="value"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch v-model="model.attr.fssp" :false-value="0" :label="model.labels.fssp" :rules="model.rule('fssp')" :true-value="1"/>
          </v-col>
          <v-col>
            <v-switch v-model="model.attr.bci" :false-value="0" :label="model.labels.bci" :rules="model.rule('bci')" :true-value="1"/>
          </v-col>
          <v-col>
            <v-switch v-model="model.attr.sb" :false-value="0" :label="model.labels.sb" :rules="model.rule('sb')" :true-value="1"/>
          </v-col>
          <v-col>
            <v-switch v-model="model.attr.fms" :false-value="0" :label="model.labels.fms" :rules="model.rule('fms')" :true-value="1"/>
          </v-col>
          <v-col>
            <v-switch v-model="model.attr.employer" :false-value="0" :label="model.labels.employer" :rules="model.rule('employer')" :true-value="1"/>
          </v-col>
        </v-row>


        <h3>Остальное</h3>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="model.attr.source_id" :items="sourceList" :label="model.labels.source_id" :rules="model.rule('source_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="model.attr.type_id" :items="typeList" :label="model.labels.type_id" :rules="model.rule('type_id')" item-text="name" item-value="id"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="field in clientFields" :key="field.id" cols="12" md="6">
            <template v-if="field.type === 0">
              <mask-input
                  v-model="model.attr['___'+field.id]"
                  :hint="field.hint"
                  :label="field.name"
                  :mask="filterMask(field.mask)"
                  :maxlength="field.params.max"
                  :prefix="field.params.prefix"
                  :rules="model.rule('___'+field.id)"
                  :suffix="field.params.suffix"
                  :unmask="field.params.unmask"
                  persistent-hint
              />
            </template>
            <template v-if="field.type === 1">
              <mask-input
                  v-model="model.attr['___'+field.id]"
                  :hint="field.hint"
                  :label="field.name"
                  :mask="filterMask(field.mask)"
                  :prefix="field.params.prefix"
                  :rules="model.rule('___'+field.id)"
                  :suffix="field.params.suffix"
                  :unmask="field.params.unmask"
                  persistent-hint
              />
            </template>
            <template v-if="field.type === 2">
              <v-switch
                  v-model="model.attr['___'+field.id]"
                  :false-value="field.values[0]"
                  :hint="field.hint"
                  :label="field.name"
                  :prefix="field.params.prefix"
                  :rules="model.rule('___'+field.id)"
                  :suffix="field.params.suffix"
                  :true-value="field.values[1]"
                  persistent-hint
              />
            </template>
            <template v-if="field.type === 3">
              <v-select
                  v-model="model.attr['___'+field.id]"
                  :hint="field.hint"
                  :items="field.values"
                  :label="field.name"
                  :multiple="!!field.multiple"
                  :prefix="field.params.prefix"
                  :rules="model.rule('___'+field.id)"
                  :suffix="field.params.suffix"
                  clearable persistent-hint
              />
            </template>
            <template v-if="field.type === 4">
              <datetime-utc-input
                  v-model="model.attr['___'+field.id]"
                  :as-string="!field.params.local"
                  :hint="field.hint"
                  :label="field.name"
                  :mask="field.params.mask"
                  :only-date="field.params.only_date"
                  :prefix="field.params.prefix"
                  :rules="model.rule('___'+field.id)"
                  :suffix="field.params.suffix"
                  persistent-hint
              />
            </template>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="can('office.manage')" cols="12" md="6">
            <v-autocomplete v-model="model.attr.office_id" :items="officeList" :label="model.labels.office_id" :rules="model.rule('office_id')" item-text="name" item-value="id" @change="updateManagerList"/>
          </v-col>
          <v-col v-if="canManageUser()" cols="12" md="6">
            <v-autocomplete v-model="model.attr.manager_id" :items="managerList" :label="model.labels.manager_id" :rules="model.rule('manager_id')" item-text="username" item-value="id"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal                                              from "@/tags/Modal";
import {Client}                                           from "@/models/Client";
import PhoneInput                                         from "@/tags/PhoneInput";
import DatetimeUtcInput                                   from "@/tags/DatetimeUtcInput";
import {mapGetters}                                       from "vuex";
import BtnIcon                                            from "@/tags/BtnIcon";
import ClientDuplicate                                    from "@/views/clients/ClientDuplicate";
import MaskInput                                          from "@/tags/MaskInput";
import {findIndex, isArray, isEmpty, isNull, isUndefined} from "lodash";
import ClientCalendarModal                                from "@/views/clientCalenar/ClientCalendarModal";
import {CLIENT_STATUSES}                                  from "@/models/ClientStatus";


export default {
  name:       "ClientForm",
  components: {ClientCalendarModal, MaskInput, ClientDuplicate, BtnIcon, DatetimeUtcInput, PhoneInput, Modal},
  props:      ['id'],
  data() {
    return {
      modal:           false,
      model:           Client.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save', this.model.attr.id);
          },
          load: () => {
            this.oldStatusId = this.model.attr.status_id;
            this.model.attr.office_id = this.model.attr.manager.office_id;
            this.updateManagerList();
            this.loadClientFields();
          },
          error: () => {
            this.$refs.form.validate();
          }
        },
        with: ['manager', 'status', 'fields']
      }),
      clientFields:    [],
      oldStatusId:     undefined,
      genderList:      [
        {label: 'Мужской', value: 'male'},
        {label: 'Женский', value: 'female'}
      ],
      meetSuccessList: [
        {label: 'Да', value: 1},
        {label: 'Нет', value: 0}
      ],
      statusList:              [],
      categoryList:            [],
      rejectionReasonList:     [],
      refundReasonList:        [],
      sourceList:              [],
      typeList:                [],
      officeList:              [],
      managerList:             [],
      havePhoneDuplicate:      false,
      haveExtraPhoneDuplicate: false,
      haveEmailDuplicate:      false,
      haveMeet:                false
    };
  },
  computed: {
    ...mapGetters(['user', 'can', 'office']),
    maxNoticeDate() {
      return this.model.attr.status_id === CLIENT_STATUSES.CALLBACK_PLUS ?
             undefined : new Date().getTime() / 1000 + (3600 * 24 * 60);
    },
    canViewCalendar() {
      return [
        CLIENT_STATUSES.MEET,
        CLIENT_STATUSES.CALLBACK,
        CLIENT_STATUSES.CALLBACK_PLUS
      ].includes(this.model.attr.status_id);
    },
    availableStatusList() {
      let oldStatus = this.statusList.find(status => status.id === this.oldStatusId);
      return this.statusList.filter(
          status => oldStatus ? oldStatus.available_status_ids.includes(status.id) ||
              oldStatus.id === status.id : status.is_default
      );
    },
    showRejectionReason() {
      return this.model.attr.status_id === CLIENT_STATUSES.DENIED;
    },
    showRefundReason() {
      return this.model.attr.status_id === CLIENT_STATUSES.REFUND;
    },
    showMeetingSuccess() {
      return this.model.attr.status_id !== CLIENT_STATUSES.MEET && this.oldStatusId === CLIENT_STATUSES.MEET;
    },
  },
  watch:    {
    modal(value) {
      if (value) {
        if (this.id) {
          this.model.attr.id = this.id;
          this.model.load();
        } else {
          this.model.clear();
          this.setModelDefaults();
        }
        this.axios.get('client-status/list').then((response) => {
          this.statusList = response.data;
          this.setDefaultStatus();
        })
        this.axios.get('client-category/list').then((response) => {
          this.categoryList = response.data;
          this.setDefaultCategory();
        })
        this.axios.get('rejection-reason/list').then((response) => this.rejectionReasonList = response.data)
        this.axios.get('refund-reason/list').then((response) => this.refundReasonList = response.data)
        this.axios.get('client-source/list').then((response) => {
          this.sourceList = response.data;
          this.setDefaultSource();
        })
        this.axios.get('lead-type/list', {params: {expand: 'fields'}}).then((response) => {
          this.typeList = response.data;
          this.loadClientFields();
        })
        if (this.can('office.manage')) this.axios.get('office/list').then((response) => this.officeList = response.data)
        if (this.canManageUser()) this.axios.get('user/list').then((response) => {
          this.managerList = response.data;
          this.setDefaultManager();
        })
      }
    },
    'model.attr.status_id'() {
      this.setModelDefaults();
    },
    'model.attr.manager_id'() {
      this.setModelDefaults()
    },
    'model.attr.type_id'() {
      this.loadClientFields();
    }
  },
  methods:  {
    updateManagerList() {
      if (this.canManageUser()) this.axios.get('user/list?office_id=' + this.model.attr.office_id).then((response) => this.managerList = response.data)
    },
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    updatePhoneDuplicate(count) {
      this.havePhoneDuplicate = !!count;
    },
    updateExtraPhoneDuplicate(count) {
      this.haveExtraPhoneDuplicate = !!count;
    },
    updateEmailDuplicate(count) {
      this.haveEmailDuplicate = !!count;
    },
    checkHaveMeet() {
      if (this.model.attr.notice_date && this.model.attr.status_id === CLIENT_STATUSES.MEET) {
        this.axios.get('client/check-meet', {params: {time: this.model.attr.notice_date, id: this.id}})
            .then((response) => this.haveMeet = !!response.data)
      } else {
        this.haveMeet = false;
      }
    },
    canManageUser() {
      return this.can('user.manage') || this.can('client.agent') || this.office.show_denied;
    },
    updateNoticeDate(notice_date) {
      this.model.attr = Object.assign({}, this.model.attr, {notice_date});
    },
    setModelDefaults() {
      if (!this.model.attr.id) {
        this.model.attr.status = this.statusList[findIndex(this.statusList, {id: this.model.attr.status_id})];
        this.model.attr.manager = this.managerList[findIndex(this.managerList, {id: this.model.attr.manager_id})] ||
            (this.canManageUser() ? {id: 0, username: 'Не выбрано'} : this.user)
      }
    },
    loadClientFields() {
      const type = this.typeList.find((item) => item.id === this.model.attr.type_id);
      this.clientFields = type ? type.fields : [];
      this.clientFields.forEach(item => {
        if (item.type === 3 && item.multiple) {
          if (!isArray(this.model.attr['___' + item.id])) {
            this.model.attr['___' + item.id] =
                !isNull(this.model.attr['___' + item.id]) && !isUndefined(this.model) ?
                [this.model.attr['___' + item.id]] : [];
          } else {
            this.model.attr['___' + item.id] = this.model.attr['___' + item.id].filter(value => !['null', 'undefined'].includes(value));
          }
        }
      })
      this.model.setFields(this.clientFields);
    },
    setDefaultStatus() {
      if (!this.id) {
        let status = this.statusList.find((item) => item.is_default);
        status && (this.model.attr.status_id = status.id);
      }
    },
    setDefaultCategory() {
      if (!this.id) {
        let category = this.categoryList.find((item) => item.is_default);
        category && (this.model.attr.category_id = category.id);
      }
    },
    setDefaultSource() {
      if (!this.id) {
        let source = this.sourceList.find(item => item.is_default)
        source && (this.model.attr.source_id = source.id);
      }
    },
    setDefaultManager() {
      if (this.canManageUser()) {
        this.model.attr.manager_id = this.user.id;
      }
    },
    fillProfile(data) {
      this.model.fill(data);
    },
    copyAddress() {
      this.model.attr = Object.assign({}, this.model.attr, {address_residence: this.model.attr.address_registration});
    },
    filterMask(mask) {
      return !isEmpty(mask) ? Object.fromEntries(Object.entries(mask).filter(([key]) => !['suffix', 'prefix'].includes(key))) : '';
    },
    isEmpty,
  }
}
</script>

<style scoped>

</style>