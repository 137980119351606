<script>

import {mapGetters, mapMutations} from "vuex";
import {isEqual}                  from "lodash";

export default {
  name: "RouteService",
  created() {
    const routeTo = this.checkRoutePermissions(this.$route);
    if (routeTo.name !== this.$route.name) {
      this.$router.push(routeTo);
    }
  },
  computed: {
    ...mapGetters(['can', 'isGuest', 'office', 'lastRoute', 'permissions'])
  },
  watch:    {
    $route: {
      handler(to) {
        const newRoute = this.checkRoutePermissions(to);
        if (!isEqual(newRoute, this.$route)) {
          this.$router.push(newRoute);
        }
      },
      deep:      true,
      immediate: true,
    },
    permissions() {
      const newRoute = this.checkRoutePermissions(this.$route);
      if (!isEqual(newRoute, this.$route)) {
        this.$router.push(newRoute);
      }
    }
  },

  methods: {
    ...mapMutations(['setLastRoute']),
    checkRoutePermissions(to) {
      if (this.canOpenRoute(to)) {
        if (to && this.canOpenRoute(to) && !this.isGuest) {
          this.setLastRoute(to);
        }
        return to;
      } else {
        if (this.isGuest) {
          return {name: 'Login'}
        } else {
          return {name: 'Home'}
        }
      }
    },
    canOpenRoute(route) {
      let permissions
      if (!(permissions = route?.meta?.permissions)) {
        return true;
      }

      if (typeof permissions == 'string') {
        return this.can(permissions);
      }
      if (typeof permissions == 'object') {
        for (const permission of permissions) {
          if (this.can(permission)) {
            return true;
          }
        }
        return false;
      }
      if (typeof permissions == 'function') {
        return permissions(this);
      }
      return false;
    },

    afterLogin() {
      if (this.lastRoute && this.canOpenRoute(this.lastRoute) && !this.canOpenRoute(this.$route) && this.lastRoute.name !== this.$route.name) {
        this.$router.push(this.lastRoute);
      } else if (!this.canOpenRoute(this.$route)) {
        this.$router.push({name: 'Home'});
      }
    },
    afterLogout() {
      if (!this.canOpenRoute(this.$route) && this.$route.name !== 'Login') {
        this.$router.push({name: 'Login'});
      }
    }
  },
  render() {
    return '';
  }
}
</script>