<template>
  <v-card>
    <v-title :title="$t('user.title')"/>
    <v-card-title>
      <span class="mr-5">{{ $t('user.title') }}</span>
      <span>
      <user-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </user-form></span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          hide-details
          :label="$t('btn.search')"
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.avatar="{ item }">
        <v-avatar size="40">
          <v-img v-if="item.avatar" :src="apiUri(item.avatar)"/>
        </v-avatar>
      </template>
      <template v-slot:item.online="{ item }">
        <btn-icon :color="onlineColor(item)" :title="item.onlinePeriod" icon="circle"/>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="item.id" class="text-right">
          <span v-if="canEdit(item)" class="ml-2">
            <user-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </user-form>
          </span>
          <span v-if="canBlock(item)" class="ml-2">
            <block :id="item.id" :name="item.username" @block="models.load()"/>
          </span>
          <span v-if="canUnblock(item)" class="ml-2">
            <unblock :id="item.id" :name="item.username" @unblock="models.load()"/>
          </span>
          <span v-if="canDelete(item)" class="ml-2">
            <delete :id="item.id" :name="item.username" @remove="models.load()"/>
          </span>
          <span v-if="canRestore(item)" class="ml-2">
            <restore :id="item.id" :name="item.username" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete        from "@/views/users/Delete";
import {mapGetters}  from "vuex";
import ToggleDeleted from "@/views/users/ToggleDeleted";
import Restore       from "@/views/users/Restore";
import DataTable     from "@/tags/DataTable";
import VTitle        from "@/tags/VTitle";
import UserForm      from "@/views/users/UserForm";
import BtnIcon       from "@/tags/BtnIcon";
import {apiUri}      from "@/helpers/stringHelpers";
import Block         from "@/views/users/Block";
import Unblock       from "@/views/users/Unblock";


export default {
  name:       "Index",
  components: {Block, Unblock, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete, UserForm},
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'user',
      fields: ['id', 'username', 'email', 'role.description', 'role.name', 'office.name', 'avatar', 'deleted_at', ' is_blocked', 'online', 'onlinePeriod'],
      expand: ['role', 'office'],
      params: {deleted: 0},
      filter: {or: [{username: {like: ''}}, {email: {like: ''}}]}
    }).load(),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['user', 'role']),
    headers() {
      return [
        // {text: 'ID', value: 'id', sortable: true},
        {text: 'Аватар', value: 'avatar'},
        {text: this.$t('user.login'), value: 'username', sortable: true},
        {text: this.$t('user.email'), value: 'email', sortable: true},
        {text: this.$t('user.role'), value: 'role.description'},
        {text: 'Офис', value: 'office.name'},
        {text: 'Online', value: 'online'},
        {text: this.$t('user.actions'), value: 'actions', align: 'end'}
      ]
    }
  },
  watch: {
    search(value) {
      this.models.config.filter.or[0].username.like = value || '';
      this.models.config.filter.or[1].email.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    canEdit(item) {
      return ((item.role?.name === 'admin' && this.role?.name === 'admin') || (item.role?.name !== 'admin')) && !item.deleted_at
    },
    canDelete(item) {
      return ((item.role?.name === 'admin' && this.role?.name === 'admin') || (item.role?.name !== 'admin')) && this.user?.id !== item.id && !item.deleted_at
    },
    canRestore(item) {
      return ((item.role?.name === 'admin' && this.role?.name === 'admin') || (item.role?.name !== 'admin')) && this.user?.id !== item.id && !!item.deleted_at
    },
    canBlock(item) {
      return ((item.role?.name === 'admin' && this.role?.name === 'admin') || (item.role?.name !== 'admin')) && this.user?.id !== item.id && !item.is_blocked
    },
    canUnblock(item) {
      return ((item.role?.name === 'admin' && this.role?.name === 'admin') || (item.role?.name !== 'admin')) && this.user?.id !== item.id && item.is_blocked
    },
    onlineColor(item) {
      const colors = {
        600:     'green',
        28800:   'lime',
        604800:  'orange',
        7776000: 'deep-orange'
      }
      if (item.online === null) {
        return 'grey'
      }
      for (let period in colors) {
        if (item.online <= period) {
          return colors[period];
        }
      }
      return 'red';
    },
    apiUri
  }
}
</script>

<style scoped>

</style>