<template>
  <modal v-model="modal" :loading="loading" :title="$t('role.manage')+' '+name">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text>
      <div v-for="item in models" :key="item.name">
        <v-switch v-model="item.allowed" :disabled="loading" :label="item.description" hide-details @change="change(item.name,item.allowed)"/>
      </div>
    </v-card-text>
    <template v-slot:actions>
      <v-btn @click="close()">{{ $t('btn.close') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal from "@/tags/Modal";

export default {
  name:       "RuleForm",
  props:      ['id', 'name'],
  components: {Modal},
  data() {
    return {
      modal:   false,
      loading: false,
      models:  []
    }
  },
  methods: {
    close() {
      this.modal = false;
      this.$emit('close')
    },
    change(name, allowed) {
      this.loading = true;
      this.axios.post('role/change?id=' + this.id, {permission: name, type: allowed ? 'allow' : 'deny'})
          .then(() => this.loading = false);
    }
  },
  watch:   {
    modal(value) {
      if (value) {
        this.loading = true;
        this.axios.get('role/permissions', {params: {id: this.id}})
            .then((response) => {
              this.models  = response.data;
              this.loading = false;
            })
      }
    }
  }
}
</script>

<style scoped>

</style>