<template>
  <modal v-model="modal" :loading="model.loading" :title="model.attr.name || 'Загружается...'" max-width="1200px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-responsive :aspect-ratio="16/9">
      <iframe v-if="modal && model.attr.embed" :src="model.attr.embed" allowfullscreen="allowfullscreen" class="player"/>
    </v-responsive>
    <div v-if="!model.loading" class="video-info">
      <div class="category">
        <v-chip class="font-weight-regular category-name" color="white">
          <span class="text-truncate">{{ model.attr?.category?.name }}</span>
        </v-chip>
        <v-spacer/>
        <v-icon v-if="!model.attr.public" class="ml-2" color="error">mdi-lock-open</v-icon>
        <v-icon v-if="model.attr.fixed" class="ml-2" color="warning">mdi-star</v-icon>
      </div>
      <div class="description">
        {{ model.attr.description }}
      </div>
    </div>
    <div v-else class="video-info">
      <v-skeleton-loader type="article"/>
    </div>
    <template #actions>
      <v-btn :disabled="disablePrev" color="primary" @click="setVideo(model.attr.prevCategoryVideoId)">
        Предыдущее <span v-if="longBtn">видео</span>
      </v-btn>
      <v-spacer/>
      <v-btn :disabled="disableNext" color="primary" @click="setVideo(model.attr.nextCategoryVideoId)">
        Следующее <span v-if="longBtn">видео</span>
      </v-btn>
    </template>
  </modal>
</template>

<script>
import {Video}        from "@/models/Video";
import Modal          from "../../tags/Modal";
import {mapMutations} from "vuex";
import {isNull}       from "lodash";

export default {
  name:       "VideoView",
  components: {Modal},
  props:      ['id'],
  data() {
    return {
      modal: false,
      model: Video.get(this.id, {
        with: [
          'category',
          'nextCategoryVideoId',
          'prevCategoryVideoId',
        ]
      })
    }
  },
  computed: {
    disablePrev() {
      return this.model.loading || isNull(this.model.attr.prevCategoryVideoId);
    },
    disableNext() {
      return this.model.loading || isNull(this.model.attr.nextCategoryVideoId);
    },
    longBtn() {
      return !this.$vuetify.breakpoint.mobile;
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        this.model.load();
      }
      this.setKeepActive(value);
    }
  },
  methods:  {
    ...mapMutations(['setKeepActive']),
    setVideo(id) {
      this.model.attr.id = id;
      this.model.load();
    }
  }
}
</script>

<style scoped>
.player {
  width: 100%;
  height: 100%;
  border: none;
}

.video-info {
  position: relative;
  padding: 16px 16px 0;

  & .category {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    & .category-name {
      overflow: hidden;
      max-width: calc(100vw - 144px);
    }
  }

  & .description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
  }
}
</style>