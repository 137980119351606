<template>
  <div class="login-form-layer d-flex justify-center fill-height align-center">
    <v-title :title="$t('login.auth')"/>
    <v-card class="login-form" max-width="500px">
      <v-card-title class="justify-center pt-6">{{ $t('login.auth') }}</v-card-title>
      <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
      <v-card-text class="px-8 pt-4">
        <v-expand-transition>
          <v-alert v-if="showAlert" type="success">
            Ссылка для авторизации отправлена на вашу почту
          </v-alert>
        </v-expand-transition>
        <v-form ref="form" :disabled="model.loading" @submit.prevent.stop="submitForm">
          <v-text-field ref="email" v-model="model.attr.email" :rules="model.rule('email')" label="Электронная почта" @keyup.enter="submitForm"/>
          <password-input ref="password" v-model="model.attr.password" :label="$t('login.password')" :rules="model.rule('password')" @keyup.enter="submitForm"/>
          <v-chip-group v-model="model.attr.period" active-class="primary-chip" class="d-flex flex-column align-center">
            <v-chip v-if="showTemp" :disabled="model.loading" color="#E0E0E0" filter value="temp">Чужой компьютер</v-chip>
            <v-chip v-if="showForever" :disabled="model.loading" color="#E0E0E0" filter value="forever">Запомнить меня</v-chip>
          </v-chip-group>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-column justify-center pb-6">
        <v-btn ref="submit" :disabled="model.loading" class="mb-2" color="primary" width="200px" @click="submitForm">{{ $t('login.signIn') }}</v-btn>
        <v-btn :to="{name:'PasswordResetRequest'}" class="mx-0" color="secondary" text>Восстановить пароль</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Form}                                 from "@/models/Form";
import VTitle                                 from "@/tags/VTitle";
import PasswordInput                          from "@/tags/PasswordInput";


export default {
  name:       "Login",
  components: {PasswordInput, VTitle},
  mounted() {
    let token = this.$route.query.token;
    if (token) {
      this.initAuth(token)
    }
    setTimeout(() => this.getFocus(), 100);
  },
  data() {
    return {
      model: Form.create({
        uri:   'auth/login',
        attrs: ['email', 'password', 'period'],
        on:    {
          send:  (data) => {
            this.model.loading = true;
            if (data === true) {
              this.model.loading = false;
              this.showAlert = true;
            } else {
              this.initAuth(data);
            }
          },
          error: (data, status) => {
            if (status === 403) {
              this.logout();
              return;
            }
            this.$refs.form.validate();
          }
        }
      }),
      showAlert: false
    };
  },
  computed: {
    ...mapGetters(['setting']),
    keyExpire() {
      return this.setting?.user?.setting?.safety?.authKeyExpire || {default: 0, temp: 0};
    },
    showTemp() {
      return this.keyExpire.temp > 0;
    },
    showForever() {
      return this.keyExpire.default > 0;
    }
  },
  methods: {
    ...mapMutations(['setAuthKey']),
    ...mapActions(['logout']),
    getFocus() {
      const email    = this.$refs.email.$el.querySelector('input');
      const password = this.$refs.password.$el.querySelector('input');
      const submit   = this.$refs.submit.$el;
      email.focus();
      if (email.value) {
        email.blur();
      } else {
        return false;
      }
      password.focus();
      {
        if (password.value) {
          password.blur();
        } else {
          return false;
        }
      }
      submit.focus();
      return true;
    },
    submitForm() {
      if (!this.getFocus()) {
        return;
      }
      this.model.send();
    },
    async initAuth(token) {
      this.setAuthKey(token);
      try {
        await this.$socket.request('/auth/login', {token, tz: new Date().getTimezoneOffset()});
      }
      catch (e) {
        await this.logout();
      }
    }
  },

}
</script>

<style scoped>

.login-form {
  width: 100%;
//max-width: 500px; //position: relative; //top: 20dvh;
}

.primary-chip {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}

</style>