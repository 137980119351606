<template>
  <div class="video-item">
    <video-view :id="item.id">
      <v-hover>
        <template #default="{ hover }">
          <v-img :aspect-ratio="16/9" :src="item.preview">
            <v-fade-transition>
              <v-overlay v-if="hover" absolute class="video-overlay" color="#0000007f">
                <btn-icon class="video-play" color="white" icon="play"/>
                <span v-if="showManage" class="video-manage">
                <btn-icon color="white" icon="pencil" title="Редактировать" @click="$refs.form.open()" @click.stop/>
                <btn-icon v-if="showDelete" :title="$t('btn.delete')" color="white" icon="delete" @click="$refs.delete.open()" @click.stop/>
                <btn-icon v-if="!showDelete" :title="$t('btn.restore')" color="white" icon="delete-off" @click="$refs.restore.open()" @click.stop/>
                </span>
              </v-overlay>
            </v-fade-transition>
          </v-img>
        </template>
      </v-hover>
      <div class="video-info">
        <div class="category">
          <v-chip class="font-weight-regular category-name" color="white">
            <span class="text-truncate">{{ item.category.name }}</span>
          </v-chip>
          <v-spacer/>
          <v-icon v-if="!item.public" class="ml-2" color="error">mdi-lock-open</v-icon>
          <v-icon v-if="item.fixed" class="ml-2" color="warning">mdi-star</v-icon>
        </div>
        <div class="name">
          {{ item.name }}
        </div>
        <div class="description">
          {{ item.description }}
        </div>
        <v-btn block color="primary">Начать просмотр</v-btn>
      </div>
    </video-view>
    <video-form :id="item.id" ref="form" @save="$emit('update')"/>
    <delete :id="item.id" ref="delete" :name="item.name" @remove="$emit('update')"/>
    <restore :id="item.id" ref="restore" :name="item.name" @restore="$emit('update')"/>
  </div>
</template>
<script>

import VideoView    from "@/views/videos/VideoView.vue";
import BtnIcon      from "@/tags/BtnIcon.vue";
import VideoForm    from "@/views/videos/VideoForm.vue";
import Delete       from "@/views/videos/Delete.vue";
import Restore      from "@/views/videos/Restore.vue";
import {mapGetters} from "vuex";

export default {
  name:       "VideoItem",
  components: {Delete, Restore, VideoForm, BtnIcon, VideoView},
  props:      {
    item: {
      type:     Object,
      required: true
    }
  },
  computed:   {
    ...mapGetters(['can']),
    showManage() {
      return this.can('video.manage');
    },
    showDelete() {
      return !this.item.deleted_at;
    }
  }
}
</script>


<style lang="scss" scoped>
.video-item {
  border-radius: 12px;
  overflow: hidden;

  & .video-info {
    border-radius: 12px;
    overflow: hidden;
    background-color: #FCF9F9;
    border: 1px solid rgba(50, 0, 0, 0.12);
    margin-top: -12px;
    position: relative;
    padding: 16px;

    & .category {
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      & .category-name {
        overflow: hidden;
        max-width: calc(100vw - 144px);
      }
    }

    & .name {
      height: 44px;
      font-size: 16px;
      margin-bottom: 4px;
      font-weight: bold;
      display: -webkit-box;
      align-items: center;
      box-orient: vertical;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    & .description {
      height: 64px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);
      display: -webkit-box;
      box-orient: vertical;
      -webkit-box-orient: vertical;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin-bottom: 8px;
    }
  }
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
}

.video-manage {
  position: absolute;
  top: 8px;
  right: 8px;
  white-space: nowrap;
}


</style>
<style lang="scss">
.video-overlay {
  & .v-overlay__content {
    width: 100%;
    height: 100%;
  }
}
</style>